import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import jwtDecode from "jwt-decode";
const iamApiUrl = process.env.REACT_APP_API_URL;

const msalConfig = {
  auth: {
    clientId: "f8970b73-4e1a-4c94-84b3-49561df99eec", // Replace with your Azure AD client ID
    // clientId: 'ae03126e-e674-4bc6-8827-bb2a2b8a06bf', // Replace with your Azure AD client ID
    // authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    authority:
      "https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2", // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    redirectUri: window.location.origin, // The URL where Azure AD will redirect after authentication
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const accessTokenRequest = (user) => ({
  // scopes: ['openid', 'profile', 'email','user.read'],
  // scopes: ["User.Read","api://f8970b73-4e1a-4c94-84b3-49561df99eec"],
  scopes: [
    // "api://f8970b73-4e1a-4c94-84b3-49561df99eec",
    "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Admins",
    "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Readers",
    // "api://f8970b73-4e1a-4c94-84b3-49561df99eec/.default"
  ],
  account: user,
  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
});

const graphApiAccessTokenRequest = (user) => ({
  scopes: ["https://graph.microsoft.com/User.Read"],
  account: user,
  storeAuthStateInCookie: false,
});

// Add this flag at the top level, outside of the hook
let isRefreshing = false;
let refreshPromise = null;

export const useUser = () => {
  // debugger
  // const { instance, inProgress, accounts } = useMsal();
  const msalInstance = new PublicClientApplication(msalConfig);
  let user = msalInstance.getAllAccounts()[0];
  // useEffect(async () => {

  //     let result = await getToken();
  //     console.log(result)
  // }, [])

  const getToken = async () => {
    let response = await msalInstance
      .acquireTokenSilent(accessTokenRequest(user))
      .catch((err) => {
        console.error(err);
        return undefined;
      });

    return response.accessToken;
  };
  const getUserToken = async () => {
    // Wait if a refresh is already in progress
    if (isRefreshing) {
      await refreshPromise;
    }

    const token = await getToken();
    if (!token) {
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("userRefreshToken");
      return null;
    }
    const currentUserToken = localStorage.getItem("userAccessToken");

    if (
      currentUserToken &&
      currentUserToken != "undefined" &&
      currentUserToken != "null"
    ) {
      const tokenExpired = jwtDecode(currentUserToken).exp < Date.now() / 1000;
      if (!tokenExpired) return currentUserToken;

      // Set the refresh lock
      isRefreshing = true;
      refreshPromise = refreshToken(currentUserToken);
      const refreshTokenResponse = await refreshPromise;
      // Release the lock
      isRefreshing = false;
      refreshPromise = null;

      if (refreshTokenResponse) {
        localStorage.setItem("userAccessToken", refreshTokenResponse.token);
        localStorage.setItem(
          "userRefreshToken",
          refreshTokenResponse.refreshToken
        );
        return refreshTokenResponse.token;
      }
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("userRefreshToken");
    }

    const request = await fetch(`${iamApiUrl}/users/token`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        var data = await res.json();
        localStorage.setItem("userAccessToken", data.token);
        localStorage.setItem("userRefreshToken", data.refreshToken);
        return data.token;
      })
      .catch((err) => {
        console.error("Error getting user token", err);
        return null;
      });

    return null;
  };
  const refreshToken = async (token) => {
    const response = await fetch(`${iamApiUrl}/auth/users/refresh-token`, {
      method: "Post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: localStorage.getItem("userRefreshToken"),
      }),
    })
      .then(async (res) => {
        if (res.status >= 400) {
          window.location.href = "/not-authorized";
          return null;
        }
        var data = await res.json();
        localStorage.setItem("userAccessToken", data.token);
        localStorage.setItem("userRefreshToken", data.refreshToken);
        return data;
      })
      .catch((err) => {
        debugger;
        console.error("Error refreshing token", err);
        return null;
      });

    return response;
  };

  const canAccessApplication = (appName) => {
    const token = localStorage.getItem("userAccessToken");
    const currentUser = jwtDecode(token);
    if (!currentUser.applications) {
      return false;
    }
    if (!Array.isArray(currentUser.applications)) {
      return appName.toLowerCase() === currentUser.applications.toLowerCase();
    }
    if (currentUser.applications.length === 0) {
      return false;
    }
    return currentUser.applications
      .map((r) => r.toLowerCase())
      .some((r) => r === appName.toLowerCase());
  };

  const hasPermission = (permissions) => {
    let permissionsArr = permissions.toLowerCase().split(",");
    for (const role of user.idTokenClaims?.roles) {
      if (permissionsArr.includes(role.toLowerCase())) return true;
    }
    return false;
  };

  const getGraphApiToken = async () => {
    let response = await msalInstance
      .acquireTokenSilent(graphApiAccessTokenRequest(user))
      .catch((err) => {
        console.error(err);
        return undefined;
      });

    return response.accessToken;
  };

  return {
    user,
    getToken,
    canAccessApplication,
    hasPermission,
    getUserToken,
    getGraphApiToken,
  };
};
//
